.blog-content{
  
    .blog-detail{
        .blog-thumbnail{
            height:auto;
        }
        .blog-title{
            p{
                -webkit-line-clamp: unset;
            }
            -webkit-line-clamp: unset;
        }
    }
    .blog-area{
        .border-bg{
            background-color: transparent;
            border: none;
            padding: 0;
        }
    }
    .post-date{
        margin-bottom: 30px;
        font-family: $font-family-base-bold;
        i{
            margin-right: 5px;
        }
    }
 
  
    .right-menu-categories {
       
        a[aria-expanded="false"]:after {
            content: "\f067";
            font-family: "Font Awesome 5 Free";
            font-weight: 900;
            font-size: 0.8125rem;
            float: right;
        }
        a[aria-expanded="true"]:after {
            content: "\f068";
            font-family: "Font Awesome 5 Free";
            font-weight: 900;
            font-size: 0.8125rem;
            float: right;
        }
        .main-manu {
            display: block;
            background-color: $main-menu-bg;
            color: color-yiq($main-menu-bg);
            border: 1px solid $gray-300;
            padding: 5px 10px;
            text-decoration: none;
            font-family: $font-family-sans-serif;
            font-size: $font-size-base;
            font-weight: $font-weight-bold;
    
            img{
                width: 16px;
                margin-right: 7px;
                margin-bottom: 4px;
            }
            .fas{
                float: right;
                display: block;
                color:$gray-300;
                margin-top: 5px;
            }
        }
        .sub-manu{
            padding-top: 10px;
            padding-bottom: 10px;
            .unorder-list{
                padding: 0;
                margin-bottom: 0;
                list-style: none;
    
                .list-item{
                    a{
                        display: block;
                        padding: 0px 40px;
                        text-decoration: none;
                        line-height: 25px;
                        &:hover{
                            color: $secondary;
                        }
                        .fas{
                            margin-right: 7px;
                        }
                    }
                }
            }
        }
    }
 
}
.blog{
    
  
    .blog-thumbnail{
        position: relative;
        margin-bottom: 15px;
        height: 240px;
        overflow: hidden;
        width: 100%;
        .over{
              
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            overflow: hidden;
            transform: translateY(-100px);
            transition: 1.2s ease-out;
            opacity: 0;
            background-color:  #dadadaa6;
            
        }
     
        .img-thumbnail{
            padding: 0;
            border: none;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        a{
           display: block;
            &:hover .over{
                    opacity: 1;
                    transition-timing-function: ease-in;
                    transform: translateY(0px) !important;
                    -webkit-transform: translateY(0px) !important;
                    transition: 0.4s;
                 
                }
          
        }
        &:hover{
            .over{
            
                opacity: 1;
                transition-timing-function: ease-in;
                transform: translateY(0px) !important;
                -webkit-transform: translateY(0px) !important;
                transition: 0.4s;
             
            }
        }
    }
    .post-title{
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
    
        overflow: hidden;
        line-height: 15px;
    }
    .blog-title{
        line-height: 1.5;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    
        overflow: hidden;
    }
    p{
        line-height: 1.5;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    
        overflow: hidden;
    }

    .blog-control{
        border-top: 1px solid $gray-300;
        padding-top: 10px;
        margin-bottom: 30px;

        .btn{
            padding: 0 5px;
        }
    }
   .blog-detial{
       text-align: center;
       .tag {
           font-family: $font-family-base;
        font-size: $font-size-base;
        color:$body-color-secondary;
        
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        -webkit-box-align: center;
        overflow: hidden;
        line-height: 15px;
        margin-bottom: 10px;
    }
    h5{
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        -webkit-box-align: center;
        overflow: hidden; 
    }
    .blog-description{
       div , p{
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            -webkit-box-align: center;
            overflow: hidden;
        }
    }
 
   }
   .over{      
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 64%;
        opacity: 0;
        overflow: hidden;
        transform: translateY(-100px);
        transition: 1.2s ease-out;
        opacity: 0;
        background-color:  #dadadaa6;
   }

}
.pro-blog .general-product{
    margin-top: 30px;
}
.pro-content .blog-carousel-js{
     .product{
         padding-top: 0;
         position: relative;
     }
}
@media screen and (max-width:992px){

    .blog-area{
        .col-12.col-lg-8{
            order: 1;
        }
    }
}